import { useState, useEffect } from "react"
import { useGLTF, useTexture } from "@react-three/drei"
import * as THREE from "three"

export function useCustomAssets(
  modelUrl: string,
  textureUrl: string
): {
  model: any | null
  texture: THREE.Texture | null
  modelError: string | null
  textureError: string | null
} {
  const [modelExists, setModelExists] = useState(false)
  const [textureExists, setTextureExists] = useState(false)
  const [modelError, setModelError] = useState<string | null>(null)
  const [textureError, setTextureError] = useState<string | null>(null)

  // Check if the model file exists
  useEffect(() => {
    async function checkFileExists(
      url: string,
      setExists: (exists: boolean) => void,
      setError: (error: string | null) => void
    ) {
      try {
        const response = await fetch(url, { method: "HEAD" })
        if (!response.ok) {
          throw new Error(`File not found at ${url}`)
        }
        setExists(true)
      } catch (error) {
        console.error(`Failed to find file at ${url}:`, error)
        setError(`File not found`)
      }
    }

    checkFileExists(modelUrl, setModelExists, setModelError)
    checkFileExists(textureUrl, setTextureExists, setTextureError)
  }, [modelUrl, textureUrl])

  const model = useGLTF(
    modelExists ? modelUrl : "/models/floorplans/A.glb"
  ) as any
  const texture = useTexture(
    textureExists ? textureUrl : "/textures/floorplans/A.webp"
  ) as THREE.Texture

  return {
    model,
    texture,
    modelError,
    textureError,
  }
}
