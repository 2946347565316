import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { CubeIcon, DimensionsIcon, EyeOpenIcon } from "@radix-ui/react-icons"
import { useStore } from "@state/store"
import View from "./View"
import Floorplan from "./Floorplan"
import { formatPrice } from "@utils/functions"
import Plan3DViewer from "@components/Plan3DViewer"

type FavCardProps = {
  unit: any
  onClose: () => void
}

const FavCard: React.FC<FavCardProps> = ({ unit, onClose }) => {
  const sections = [
    { name: "3D", icon: CubeIcon, component: <Plan3DViewer /> },
    {
      name: "Floorplan",
      icon: DimensionsIcon,
      component: <Floorplan unit={unit} />,
    },
    { name: "View", icon: EyeOpenIcon, component: <View /> },
  ]
  const [circlePosition, setCirclePosition] = useState<number>(0)
  const [currentSection, setCurrentSection] = useState<number>(0)
  const iconRefs = useRef<Array<HTMLDivElement | null>>([])
  const { setLocalState, sectionIndex } = useStore((s) => ({
    setLocalState: s.setLocalState,
    sectionIndex: s.sectionIndex,
  }))

  const handleIconClick = (index: number) => {
    if (index === sectionIndex) return

    setLocalState({ sectionIndex: index })
  }

  useEffect(() => {
    const iconElement = iconRefs.current[sectionIndex]
    if (iconElement) {
      const iconRect = iconElement.getBoundingClientRect()
      const parentRect = iconElement.parentElement!.getBoundingClientRect()
      const position = iconRect.left - parentRect.left + iconRect.width / 2
      setCirclePosition(position)

      const timeoutId = setTimeout(() => {
        setCurrentSection(sectionIndex)
      }, 300)

      return () => clearTimeout(timeoutId)
    }
  }, [sectionIndex])

  return (
    <Wrapper>
      <Container
        isVisible={sections.indexOf(sections[currentSection]) === sectionIndex}
      >
        <div className="section">
          <h1 className="unit-name">{unit?.unitName}</h1>
          <h1 className="active-section">{sections[currentSection]?.name}</h1>
        </div>
        <Content>
          {sections.map((section, index) => (
            <SectionWrapper key={index} isVisible={index === currentSection}>
              {section.component}
            </SectionWrapper>
          ))}
        </Content>
        <div className="unit-info">
          <h2>{formatPrice(unit?.price)}</h2>
          <h2>Floor {unit?.floor}</h2>
          <h2>Beds {unit?.beds}</h2>
          <h2>Baths {unit?.baths}</h2>
        </div>
      </Container>
      <Footer>
        {sections.map((section, index) => {
          const IconComponent = section.icon
          return (
            <IconWrapper
              key={index}
              ref={(el) => (iconRefs.current[index] = el)}
              isActive={index === sectionIndex}
              onClick={() => handleIconClick(index)}
            >
              <IconComponent color="#fff" height={20} width={20} />
            </IconWrapper>
          )
        })}
        <ActiveCircle position={circlePosition} />
      </Footer>
    </Wrapper>
  )
}

export default FavCard

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colours.secondary};
`

const Container = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100%;
  width: 100%;
  padding: 0 var(--side-padding);

  .section {
    z-index: 2;
    width: 100%;
    margin-bottom: auto;
    display: flex;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colours.primary};

    .unit-name {
      flex: 1;

      span {
        font-weight: 700;
        color: ${({ theme }) => theme.colours.accent};
      }
    }
  }

  .active-section {
    color: ${({ theme }) => theme.colours.primary};
    z-index: 2;
    height: fit-content;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .unit-info {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    color: ${({ theme }) => theme.colours.primary};
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SectionWrapper = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`

const Footer = styled.div`
  width: 100%;
  height: var(--footer-height);
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  padding: 0 var(--side-padding);
`

const IconWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  z-index: 1;
  color: ${({ isActive }) => (isActive ? "#fff" : "#aaa")};
`

const ActiveCircle = styled.div<{ position: number }>`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  transition: all 0.3s ease;
  top: 50%;
  left: ${({ position }) => position}px;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  background: ${({ theme }) => theme.colours.primary};
`
