import styled from "styled-components"
import Header from "@components/Header"
import Navbar from "@components/Navbar"
import { useStore } from "@state/store"
import { Outlet } from "react-router-dom"
import { useEffect } from "react"
import { useAuth } from "@auth/AuthContext"
import { updateCustomerVisits } from "@utils/requests"

type TParagraphTypes = "copy" | " subtitle" | "quote"

type TArticleP = {
  text: string[]
  type: TParagraphTypes
}

export type TArticle = {
  postDate?: string
  image: string
  title: string
  paragraphs?: TArticleP[]
  content?: string
  id?: string
}

const DefaultLayout: React.FC = () => {
  const { user } = useAuth()
  const isLayoutShifted = useStore((s) => s.isLayoutShifted)

  useEffect(() => {
    const updateCustomerVisitAsync = async () => {
      // await updateCustomerVisits(user?.QRID || "")
    }

    updateCustomerVisitAsync()
  }, [])

  const title = location.pathname.split("/").pop()
  return (
    <Layout isLayoutShifted={isLayoutShifted}>
      <Title>{title}</Title>
      <Header />
      <Navbar />
      <Content>
        <Outlet />
      </Content>
    </Layout>
  )
}

export default DefaultLayout

const Layout = styled.div<{ isLayoutShifted: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  transform: ${({ isLayoutShifted }) =>
    isLayoutShifted ? "translateX(-150px)" : "translateX(0)"};
  transition: transform 0.3s ease-in-out;
  width: 100vw;
  background: #191922;
`

const Content = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: calc(100% - var(--nav-height) * 2);
  z-index: 2;

  @media (orientation: landscape) {
    width: 90%;
    height: 100%;
  }
`

const Title = styled.h1`
  position: fixed;
  top: var(--nav-height);
  left: var(--side-padding);
  width: 100%;
  color: white;
  background: #191922;
  z-index: 3;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
`
