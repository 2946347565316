import styled from "styled-components"

export default function Contact() {
  return (
    <ContactWrapper>
      <h1>CONTACT</h1>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  font-size: 1em;

  h1 {
    color: white;
  }
`
