import { useEffect, useRef, useState } from "react"
import * as THREE from "three"
import { useLoader } from "@react-three/fiber"
import gsap, { Sine } from "gsap"
import { Html } from "@react-three/drei"
import { useStore } from "@state/store"

type TProps = {
  texture: string
  hasLimit?: boolean
  callback?: () => void
  initialPosition: [number, number, number]
}

function Sphere({ texture, callback, initialPosition }: TProps) {
  const matRef = useRef<THREE.MeshBasicMaterial>()
  const sectionIndex = useStore((s) => s.sectionIndex)
  const [isTextureLoaded, setIsTextureLoaded] = useState(false)

  // Preload image
  useEffect(() => {
    let t = null
    const img = new Image()
    img.src = texture
    img.onload = () => {
      t = setTimeout(() => {
        setIsTextureLoaded(true)
      }, 300)
    }
    img.onerror = (error) => {
      console.error("Failed to load texture:", error)
      setIsTextureLoaded(false)
    }

    return () => {
      img.onload = null
      img.onerror = null
      setIsTextureLoaded(false)

      clearTimeout(t)
    }
  }, [texture])

  // Load texture with useLoader after the image is preloaded
  const mainTexture = isTextureLoaded
    ? useLoader(THREE.TextureLoader, texture, () => {
        callback && callback()
      })
    : null

  if (mainTexture) {
    mainTexture.minFilter = THREE.LinearFilter
    mainTexture.wrapS = THREE.RepeatWrapping
    mainTexture.repeat.x = -1
  }

  useEffect(() => {
    gsap.to(matRef.current, {
      duration: 0,
      opacity: 0,
      ease: Sine.easeInOut,
    })
  }, [])

  useEffect(() => {
    if (sectionIndex === 2 && mainTexture) {
      gsap.to(matRef.current, {
        duration: 1,
        delay: 0.1,
        opacity: 1,
        ease: Sine.easeInOut,
      })
    }
  }, [sectionIndex, mainTexture])

  return (
    <>
      {!isTextureLoaded && (
        <Html center>
          <div
            style={{
              height: "40px",
              width: "40px",
              border: "2px solid rgba(255, 255, 255, 0.3)",
              borderTop: "2px solid #fff",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
            }}
          />
        </Html>
      )}

      <mesh
        visible={isTextureLoaded}
        position={[0, 0, 0]}
        rotation={initialPosition}
      >
        <sphereGeometry args={[800, 60, 40]} />
        <meshBasicMaterial
          toneMapped={false}
          map={mainTexture}
          side={THREE.BackSide}
          ref={matRef}
          transparent
        />
      </mesh>
    </>
  )
}

export default Sphere
