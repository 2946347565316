import { Suspense } from "react"
import styled from "styled-components"
import { Canvas } from "@react-three/fiber"
import CameraControls from "@components/CameraControls"
import Sphere from "@components/Sphere"

type TProps =
  | {
      texture: string
      textureCallback?: () => void
      camera?: any
      hasLimit?: undefined | false
      initialPosition?: [number, number, number]
    }
  | {
      texture: string
      textureCallback?: () => void
      camera?: any
      hasLimit?: true
      initialPosition?: [number, number, number]
      maxHor: number
      minHor: number
      maxVer: number
      minVer: number
    }

function SphericalTour(props: TProps) {
  return (
    <Scene camera={props.camera}>
      <Suspense fallback={null}>
        {props.hasLimit ? (
          <>
            <CameraControls
              hasLimit
              minHor={props.minHor}
              maxHor={props.maxHor}
              minVer={props.minVer}
              maxVer={props.maxVer}
            />
            <Sphere
              texture={props.texture}
              callback={props.textureCallback}
              initialPosition={props.initialPosition ?? [0, 0, 0]}
              hasLimit
            />
          </>
        ) : (
          <>
            <CameraControls />
            <Sphere
              texture={props.texture}
              callback={props.textureCallback}
              initialPosition={props.initialPosition ?? [0, 0, 0]}
            />
          </>
        )}
      </Suspense>
    </Scene>
  )
}

export default SphericalTour

const Scene = styled(Canvas)`
  width: 100%;
  height: 100%;
`
