import styled from "styled-components"
import { useEffect } from "react"
import { useStore } from "@state/store"
import { TArticle } from "../../DefaultLayout"

function Article({ data }: { data: TArticle }) {
  const { setLocalState } = useStore((s) => ({
    setLocalState: s.setLocalState,
  }))

  useEffect(() => {
    return () => {
      setLocalState({
        activeArticle: null,
      })
    }
  }, [])

  const paragraphs = data?.content?.split("\n\n").filter(Boolean)

  return (
    data && (
      <ArticleWrapper>
        <h1>{data.title}</h1>
        <img src={data.image} />
        {paragraphs
          ? paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph.trim()}</p>
            ))
          : "No content"}
      </ArticleWrapper>
    )
  )
}

export default Article

const ArticleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colours.secondary};
  padding: 0 var(--side-padding);

  & h1 {
    color: ${({ theme }) => theme.colours.primary};
    font-weight: 200;
  }

  & img {
    width: 100%;
    height: 30vh;
    object-fit: cover;
    object-position: center;
    padding: 20px 0;
  }

  & p {
    color: ${({ theme }) => theme.colours.primary};
    padding: 10px 0;
  }
`
