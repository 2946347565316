import styled from "styled-components"
import { useStore } from "@state/store"
import SphericalTour from "./SphericalTour"
import { organizeAspects } from "@utils/functions"

function View() {
  const selectedUnit = useStore((s) => s.selectedUnit)
  const views = selectedUnit ? organizeAspects([...selectedUnit["aspect"]]) : []
  const maxFloor = 25
  const totalViews = 7
  const height = selectedUnit
    ? Math.round((totalViews / maxFloor) * selectedUnit.floor)
    : 5

  const textures = {
    1: "/assets/images/views/13m.webp",
    2: "/assets/images/views/28m.webp",
    3: "/assets/images/views/38m.webp",
    4: "/assets/images/views/50m.webp",
    5: "/assets/images/views/62m.webp",
    6: "/assets/images/views/70m.webp",
    7: "/assets/images/views/82m.webp",
  }

  const rotation = {
    N: -1.25,
    E: 0.1,
    S: 1.8,
    W: 3.2,
  }

  const limit = {
    1: -0.7,
    2: -1.9,
    3: -3.5,
    4: -5.1,
  }

  return (
    selectedUnit && (
      <AptViewWrapper>
        <SphericalTour
          key={selectedUnit.id}
          texture={textures[height]}
          hasLimit
          initialPosition={[0, rotation[views[0]], 0]}
          minHor={limit[views.length]}
          maxHor={0}
          minVer={1.57}
          maxVer={1.5}
          camera={{
            fov: 58,
          }}
        />
      </AptViewWrapper>
    )
  )
}

export default View

const AptViewWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
