import { useBranding } from "@branding/BrandingContext"
import React from "react"
import styled, { css } from "styled-components"
import { SVGIcon } from "./SVGIcon"

interface LabelProps {
  icon?: string | React.ReactNode | string
  color?: "primary" | "secondary" | "accent"
  label?: string | number
  borderRadius?: string
  variant?: "fill" | "outline" | "ghost"
  className?: string
}

export const Label: React.FC<LabelProps> = ({
  icon,
  color = "primary",
  label,
  borderRadius = "0",
  variant = "fill",
  className,
}) => {
  const { theme: branding } = useBranding()
  const colorScheme = branding.colours[color] || branding.colours.primary

  return (
    <LabelWrapper
      className={className}
      variant={variant}
      colorScheme={colorScheme}
      borderRadius={borderRadius}
      hasLabel={!!label}
    >
      {icon && (
        <IconWrapper hasLabel={!!label}>
          {typeof icon === "string" ? (
            <SVGIcon url={icon} color="#fff" />
          ) : (
            icon
          )}
        </IconWrapper>
      )}
      {label && <span>{label}</span>}
    </LabelWrapper>
  )
}

const LabelWrapper = styled.div<{
  variant: "fill" | "outline" | "ghost"
  colorScheme: string
  borderRadius: string
  hasLabel: boolean
}>`
  display: inline-flex;
  align-items: center;
  justify-content: ${({ hasLabel }) => (hasLabel ? "flex-start" : "center")};
  padding: ${({ hasLabel }) => (hasLabel ? "0.5em 1em" : "0.5em")};
  border-radius: ${({ borderRadius }) => borderRadius};
  font-size: 0.75rem;
  width: fit-content;
  transition: all 0.3s ease;

  ${({ variant, colorScheme }) => {
    switch (variant) {
      case "fill":
        return css`
          background-color: ${colorScheme};
          color: #fff;
        `
      case "outline":
        return css`
          background-color: transparent;
          color: ${colorScheme};
          border: 1px solid ${colorScheme};
        `
      case "ghost":
        return css`
          background-color: transparent;
          color: ${colorScheme};
        `
      default:
        return ""
    }
  }}
`

const IconWrapper = styled.div<{ hasLabel: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ hasLabel }) => (hasLabel ? "0 0.5em 0 0" : "0")};

  img,
  svg {
    max-width: 16px;
    max-height: 16px;
  }
`
