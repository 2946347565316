import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import PWAIcons from "@components/PWAIcons"
import { GearIcon } from "@radix-ui/react-icons"
import { useStore } from "@state/store"
import { useBranding } from "@branding/BrandingContext"
import PopupCard from "@components/PopupCard"
import { SVGIcon } from "@components/UI/SVGIcon"

function Header() {
  const { theme: branding } = useBranding()
  const { handleNav, popupCard, setLocalState, notifications } = useStore(
    (s) => ({
      popupCard: s.popupCard,
      handleNav: s.handleNav,
      setLocalState: s.setLocalState,
      notifications: s.notifications,
    })
  )

  const notificationsIcon =
    notifications === "granted" ? "notifications-on" : "notifications-off"

  const newLogo = branding.logos.light.replace(
    "http://localhost:3000",
    import.meta.env.VITE_PAYLOAD_SERVER_URL
  )

  const navigate = useNavigate()
  return (
    <HeaderWrapper>
      <img
        src={newLogo}
        className="imageSize"
        onClick={() => {
          handleNav(() => navigate("/"))
        }}
      />

      <SVGIcon
        url={`/icons/${notificationsIcon}.svg`}
        color="rgb(255, 255, 255)"
        style={{ height: "30px" }}
        onClick={() => {
          setLocalState({ popupCard: !popupCard })
        }}
      />
      <PopupCard>
        <div>Allow Notifications</div>
      </PopupCard>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: var(--header-height);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--side-padding);

  @media (orientation: landscape) {
    width: 90%;
  }

  .imageSize {
    height: min(5vh, 5vw);
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
  }
`
