import styled from "styled-components"
import { Canvas } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"
import * as THREE from "three"
import PlanMesh from "./components/PlanMesh"
import { useEffect, useState } from "react"

function Plan3DViewer() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const t = setTimeout(() => {
      setLoading(false)
    }, 500)

    return () => clearTimeout(t)
  }, [])

  return (
    <PlanWrapper>
      {loading && <div className="spinner" />}
      <Canvas
        camera={{
          fov: 20,
          position: new THREE.Vector3(1, 1, 1),
        }}
      >
        <OrbitControls
          maxPolarAngle={1.5}
          minPolarAngle={0.5}
          //   enableZoom={false}
          minDistance={1}
          maxDistance={5}
          autoRotate={false}
          rotateSpeed={0.3}
        />
        <PlanMesh />
      </Canvas>
    </PlanWrapper>
  )
}

export default Plan3DViewer

const PlanWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    position: absolute;
    height: 40px;
    width: 40px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #fff;
    border-radius: 50%;
    z-index: 1;
    animation: spin 1s linear infinite;
  }
`
