// src/context/BrandingContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react"
import { ThemeProvider } from "styled-components"
import { getBranding } from "@utils/requests"
import { setDocumentStyles } from "@utils/setDocumentStyles"
import { createTheme } from "@theme/createTheme"
import { ITheme } from "@theme/types"
import LoadingScreen from "@components/LoadingScreen"

interface BrandingContextType {
  theme: ITheme | null
  loading: boolean
}

const BrandingContext = createContext<BrandingContextType | undefined>(
  undefined
)

export const BrandingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<ITheme | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let t = null

    const fetchBranding = async () => {
      const brandingData = await getBranding()

      const themeData = createTheme(brandingData)

      setTheme(themeData)

      setDocumentStyles(brandingData)

      t = setTimeout(() => {
        setLoading(false)
      }, 1000)
    }

    fetchBranding()

    return () => clearTimeout(t)
  }, [])

  if (loading || !theme) {
    return <LoadingScreen />
  }

  return (
    <BrandingContext.Provider value={{ theme, loading }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </BrandingContext.Provider>
  )
}
export const useBranding = () => {
  const context = useContext(BrandingContext)
  if (!context) {
    throw new Error("useBranding must be used within a BrandingProvider")
  }
  return context
}
