import React, { useEffect, useRef } from "react"
import styled from "styled-components"

type SvgIconProps = {
  url: string
  color?: string
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}

export const SVGIcon: React.FC<SvgIconProps> = ({
  url,
  color = "",
  className,
  style,
  onClick,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const loadSVG = async () => {
      if (containerRef.current) {
        const response = await fetch(url)
        const svgText = await response.text()
        containerRef.current.innerHTML = svgText
      }
    }

    loadSVG()
  }, [url])

  return (
    <Wrapper
      color={color}
      ref={containerRef}
      className={className}
      style={style}
      onClick={onClick}
    ></Wrapper>
  )
}

const Wrapper = styled.div<{ color: string }>`
  line-height: 0;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${(p) => p.color};
      transition: stroke 1.3s;
    }
  }
`
