import styled from "styled-components"
import { HomeIcon, HeartIcon, InfoCircledIcon } from "@radix-ui/react-icons"

type TPWAIconsProps = {
  icon: "plus" | "close" | "home" | "heart" | "phone" | "contact"
  strokeWidth?: number
  strokeColor?: string
  color: string
  badge?: number
} & React.ComponentPropsWithoutRef<"svg">

function PWAIcons(props: TPWAIconsProps) {
  const {
    icon,
    strokeWidth = 1,
    strokeColor,
    color,
    badge = 0,
    ...rest
  } = props
  return (
    <IconWrapper>
      <svg viewBox="0 0 100 100" {...rest}>
        {icon === "phone" && (
          <path
            d="M6,69.23c0-1.31,0.98-2.42,2.29-2.57l22.23-2.57c0.79-0.09,1.57,0.18,2.13,0.74l10.11,10.11
	c0.79,0.79,2.01,0.99,2.99,0.46c12.53-6.79,22.9-17.11,29.69-29.68c0.53-0.98,0.34-2.2-0.46-2.99L64.82,32.55
	c-0.56-0.56-0.83-1.34-0.74-2.13l2.57-22.14C66.81,6.99,67.91,6,69.23,6h22.19c1.37,0,2.51,1.08,2.55,2.45
	C95.32,56.3,56.3,95.32,8.45,93.97C7.08,93.93,6,92.79,6,91.42V69.23z"
            fill={color}
            strokeWidth={strokeWidth}
            stroke={strokeColor ?? color}
          />
        )}
        {icon === "heart" && <HeartIcon color={color} height={70} width={70} />}
        {icon === "contact" && (
          <InfoCircledIcon color={color} height={70} width={70} />
        )}
        {icon === "home" && <HomeIcon color={color} height={70} width={70} />}
        {icon === "plus" && (
          <g>
            <line
              x1="50"
              y1="6"
              x2="50"
              y2="94"
              fill={color}
              strokeWidth={strokeWidth}
              stroke={strokeColor ?? color}
            />
            <line
              x1="6"
              y1="50"
              x2="94"
              y2="50"
              fill={color}
              strokeWidth={strokeWidth}
              stroke={strokeColor ?? color}
            />
          </g>
        )}
        {icon === "close" && (
          <g>
            <line
              x1="6"
              y1="6"
              x2="94"
              y2="94"
              fill={color}
              strokeWidth={strokeWidth}
              stroke={strokeColor ?? color}
            />
            <line
              x1="94"
              y1="6"
              x2="6"
              y2="94"
              fill={color}
              strokeWidth={strokeWidth}
              stroke={strokeColor ?? color}
            />
          </g>
        )}

        {/* ALWAYS LAST */}
      </svg>
      {badge > 0 && (
        <svg
          viewBox="0 0 100 100"
          width="60%"
          style={{
            position: "absolute",
            top: "-20%",
            right: "-35%",
          }}
        >
          <g>
            <circle r={50} cx={50} cy={50} fill="red" />
            <text
              x="53%"
              y="55%"
              fill="white"
              fontSize={80}
              textAnchor="middle"
              dominantBaseline="middle"
            >
              {badge}
            </text>
          </g>
        </svg>
      )}
    </IconWrapper>
  )
}

export default PWAIcons

const IconWrapper = styled.div`
  position: relative;
`
