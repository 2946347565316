// src/serviceWorkerRegistration.ts
export function register() {
  console.log("called")
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        // .register("/sw.js", { type: "module" }) // Ensure this matches the compiled JavaScript file
        .register("/sw.js") // this is for production build
        .then((registration) => {
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope
          )

          registration.onupdatefound = () => {
            const installingWorker = registration.installing
            if (installingWorker == null) {
              return
            }
            installingWorker.onstatechange = () => {
              if (installingWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  // New content is available; please refresh.
                  console.log("New content is available; please refresh.")
                  if (
                    confirm("New content is available! Click OK to refresh.")
                  ) {
                    window.location.reload()
                  }
                } else {
                  // Content is cached for offline use.
                  console.log("Content is cached for offline use.")
                }
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error during service worker registration:", error)
        })
    })
  }
}
