import React, { Fragment, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import gsap from "gsap"
import { useStore } from "@state/store"
import { useBranding } from "@branding/BrandingContext"

type PopupCardProps = {
  children: React.ReactNode
}

const PopupCard: React.FC<PopupCardProps> = (props) => {
  const { theme: branding } = useBranding()
  const { popupCard, setLocalState } = useStore((s) => ({
    popupCard: s.popupCard,
    setLocalState: s.setLocalState,
  }))
  const [isAnimating, setIsAnimating] = useState(false)
  const cardRef = useRef<HTMLDivElement>(null)
  const screenRef = useRef<HTMLDivElement>(null)
  const timeline = gsap.timeline()

  const initialY = useRef(0)
  const cardRect = useRef<DOMRect | null>(null)
  const transform = useRef(0)
  const threshold = -60

  function onPointerMove(e: TouchEvent) {
    const clientY = e.touches[0].clientY
    const length = clientY - initialY.current
    const height = cardRect.current?.height || 0
    const percent = -100 + (length * 100) / height
    if (percent < -100) return
    transform.current = percent
    gsap.set(cardRef.current, {
      yPercent: percent,
    })
  }

  function onPointerUp() {
    initialY.current = 0
    if (transform.current < threshold) {
      gsap.to(cardRef.current, {
        yPercent: -100,
      })
      transform.current = -100
    } else {
      setLocalState({
        popupCard: false,
      })
      transform.current = 0
    }
  }

  function onPointerDown(e: React.TouchEvent) {
    const clientY = e.touches[0].clientY
    initialY.current = clientY
    cardRect.current = cardRef.current!.getBoundingClientRect()
  }

  useEffect(() => {
    if (!cardRef.current || !screenRef.current) return
    timeline.to(
      screenRef.current,
      {
        autoAlpha: popupCard ? 1 : 0,
      },
      0
    )
    timeline.to(
      cardRef.current,
      {
        yPercent: popupCard ? -100 : 0,
        duration: 0.3,
        ease: "none",
      },
      "<20%"
    )
  }, [popupCard])

  useEffect(() => {
    if (popupCard) {
      setIsAnimating(true)
    } else {
      setIsAnimating(true)
      setTimeout(() => {
        setIsAnimating(false)
      }, 300) // Match with the animation duration
    }
  }, [popupCard])

  if (!isAnimating && !popupCard) return null

  return ReactDOM.createPortal(
    branding && (
      <Fragment>
        <Screen
          ref={screenRef}
          onClick={() => {
            setLocalState({
              popupCard: false,
            })
          }}
        />
        <CardWrapper ref={cardRef}>
          <Header>
            <Line />
          </Header>

          <DragTarget
            onTouchStart={onPointerDown}
            //@ts-ignore
            onTouchMove={onPointerMove}
            onTouchEnd={onPointerUp}
          />
          {props.children}
        </CardWrapper>
      </Fragment>
    ),
    document.body
  )
}

export default PopupCard

const CardWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: calc(infinity);
  width: 100%;
  height: fit-content;
  min-height: 50vh;
  border-radius: 20px 20px 0 0;
  color: white;
  padding: 0 var(--side-padding);
  background-color: ${({ theme }) => theme.colours.primary};
  transform: translateY(100%);
`

const Screen = styled.div`
  position: fixed;
  bottom: 0;
  left: -2.5%;
  z-index: calc(infinity);
  width: 105%;
  height: 105%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
`

const Line = styled.div`
  position: absolute;
  z-index: calc(infinity);
  width: calc(100% / 3);
  height: 5px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  background-color: rgba(124, 124, 124, 0.8);
  mix-blend-mode: exclusion;
`

const Header = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
`

const DragTarget = styled.div`
  position: absolute;
  z-index: calc(infinity);
  width: 100%;
  height: 40px;
  top: 0;
  left: 0;
`
