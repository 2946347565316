import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { CheckCircledIcon } from "@radix-ui/react-icons"
import Button from "@components/UI/Button"
import { useAuth } from "@auth/AuthContext"
import { useBranding } from "../../branding/BrandingContext"

const Login: React.FC = () => {
  const { login, loading } = useAuth()
  const [QRID, setQRIDInput] = useState("")
  const [message, setMessage] = useState<{
    text: string
    type: "error" | "success"
  } | null>(null)
  const [showMessage, setShowMessage] = useState(true)
  const [success, setSuccess] = useState(false)
  const { theme: branding } = useBranding()
  const navigate = useNavigate()

  const handleLogin = async () => {
    const response = await login(QRID)
    setSuccess(response.success)
    setMessage({
      text: response.message,
      type: response.success ? "success" : "error",
    })
    setShowMessage(true)

    if (response.success) {
      navigate("/")
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQRIDInput(e.target.value)
    setShowMessage(false)
    setSuccess(false)
  }

  useEffect(() => {
    if (!showMessage && message) {
      const timeoutId = setTimeout(() => setMessage(null), 500)
      return () => clearTimeout(timeoutId)
    }
  }, [showMessage, message])

  const newLogo = branding.logos.light.replace(
    "http://localhost:3000",
    import.meta.env.VITE_PAYLOAD_SERVER_URL
  )

  return (
    <LoginWrapper>
      <LogoWrapper>
        <img src={newLogo} alt="Logo" />
      </LogoWrapper>
      <div className="form-wrapper">
        <MessageWrapper>
          {message && (
            <Message $type={message.type} $visible={showMessage}>
              {message.text}
            </Message>
          )}
        </MessageWrapper>
        <InputWrapper>
          <input
            type="password"
            value={QRID}
            onChange={handleInputChange}
            placeholder="5 digit user code"
            disabled={loading}
            style={{
              borderRadius: 50,
              marginBottom: "1rem",
            }}
          />
          {success && <SuccessIcon />}
        </InputWrapper>
        <Button
          onClick={handleLogin}
          disabled={loading}
          color="secondary"
          style={{ width: "100%", borderRadius: 50 }}
        >
          {loading ? "Loading..." : "Login"}
        </Button>
      </div>
    </LoginWrapper>
  )
}

export default Login

const LoginWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #191922;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15%;
  gap: 20%;
  margin-top: -20%;

  .form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const LogoWrapper = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: 3em;
  }
`

const MessageWrapper = styled.div`
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Message = styled.div<{ $type: "error" | "success"; $visible: boolean }>`
  color: ${(p) => (p.$type === "error" ? "#970000" : "green")};
  font-size: 14px;
  text-align: center;
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const SuccessIcon = styled(CheckCircledIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: green;
  font-size: 20px;
`
