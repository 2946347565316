import { useBranding } from "@branding/BrandingContext"
import React, { ButtonHTMLAttributes, ReactNode, useState } from "react"
import styled, { css, keyframes } from "styled-components"

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "fill" | "outline" | "text"
  color?: "primary" | "secondary" | "accent"
  icon?: ReactNode | string
  iconPosition?: "left" | "right"
  onClick?: () => void | Promise<void>
}

const Button: React.FC<ButtonProps> = ({
  variant = "fill",
  color = "accent",
  icon,
  iconPosition = "left",
  onClick,
  children,
  className,
  style,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { theme: branding } = useBranding()

  const handleClick = async () => {
    if (onClick) {
      const result = onClick()
      if (result instanceof Promise) {
        setIsLoading(true)
        try {
          await result
        } finally {
          setIsLoading(false)
        }
      }
    }
  }

  const colorScheme = branding.colours[color] || branding.colours.primary
  const borderRadius = branding.borderStyle === "rounded" ? "50px" : "0px"

  return (
    <StyledButton
      variant={variant}
      colorScheme={colorScheme}
      borderRadius={borderRadius}
      onClick={handleClick}
      className={className}
      style={style}
      disabled={isLoading}
      iconPosition={iconPosition}
      hasIcon={!!icon}
      {...props}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {icon && iconPosition === "left" && (
            <IconWrapper iconPosition={iconPosition} hasChildren={children}>
              {typeof icon === "string" ? <img src={icon} alt="icon" /> : icon}
            </IconWrapper>
          )}
          {children && <Text>{children}</Text>}
          {icon && iconPosition === "right" && (
            <IconWrapper iconPosition={iconPosition} hasChildren={children}>
              {typeof icon === "string" ? <img src={icon} alt="icon" /> : icon}
            </IconWrapper>
          )}
        </>
      )}
    </StyledButton>
  )
}

export default Button

interface StyledButtonProps {
  variant: "fill" | "outline" | "text"
  colorScheme: string
  borderRadius: string
  iconPosition: "left" | "right"
  hasIcon: boolean
}

const hoverStyles = {
  fill: css`
    filter: brightness(1.1);
  `,
  outline: css`
    background-color: rgba(255, 255, 255, 0.1);
  `,
  text: css`
    text-decoration: underline;
  `,
}

const StyledButton = styled.button<StyledButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: ${({ hasIcon, iconPosition }) =>
    hasIcon ? (iconPosition === "left" ? "flex-start" : "flex-end") : "center"};
  /* width: 100%; */
  width: max-content;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ hasIcon }) => (hasIcon ? "0.5em 1.5em" : "0.5em 1.5em")};

  ${({ variant, colorScheme }) => {
    switch (variant) {
      case "fill":
        return css`
          background-color: ${colorScheme};
          color: #fff;
          border: none;

          &:hover {
            ${hoverStyles.fill}
          }

          &:active {
            opacity: 0.5;
            transform: scale(0.95);
          }
        `
      case "outline":
        return css`
          background-color: transparent;
          color: ${colorScheme};
          border: 2px solid ${colorScheme};

          &:hover {
            ${hoverStyles.outline}
          }

          &:active {
            opacity: 0.5;
            transform: scale(0.95);
          }
        `
      case "text":
        return css`
          background-color: transparent;
          color: ${colorScheme};
          border: none;

          &:hover {
            ${hoverStyles.text}
          }

          &:active {
            opacity: 0.5;
            transform: scale(0.95);
          }
        `
      default:
        return ""
    }
  }}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

const IconWrapper = styled.div<{
  iconPosition: "left" | "right"
  hasChildren: any
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ iconPosition }) =>
    iconPosition === "left" ? "0 8px 0 0" : "0 0 0 8px"};

  ${({ hasChildren }) => !hasChildren && "margin: 0;"}

  img {
    max-width: 16px;
    max-height: 16px;
  }
`

const Text = styled.span`
  flex: 1;
  text-align: center; // Align text to center when there's no icon
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #fff;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`
