import styled from "styled-components"
import Button from "@components/UI/Button"
import { Label } from "@components/UI/Label"

type TPreviewCardProps = {
  data?: any
  status?: string
  buttonClick: () => void
} & React.ComponentPropsWithoutRef<"div">

function PreviewCard(props: TPreviewCardProps) {
  const { data, status, buttonClick, ...rest } = props

  return (
    <CardWrapper {...rest} onClick={buttonClick}>
      <div className="image-wrapper">
        {status && status !== "available" && (
          <Label
            label={status}
            color="accent"
            variant="fill"
            borderRadius="50px"
            className="label"
          />
        )}

        <img src={data?.image} alt={data.title} />
      </div>
      <div className="info-wrapper">
        <h2>{data.title}</h2>
        <Button
          onClick={buttonClick}
          style={{
            height: "2.5rem",
            background: "var(--secondary)",
            borderRadius: "50px",
            fontSize: "0.75rem",
          }}
        >
          View Details
        </Button>
      </div>
    </CardWrapper>
  )
}

export default PreviewCard

const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 20em;
  height: 20em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1;
  /* background-color: ${({ theme }) => theme.colours.accent}1A; */
  border: 1px solid ${({ theme }) => theme.colours.accent}1A;
  padding: 1.25rem 1rem;
  border-radius: 10px;

  .image-wrapper {
    margin: auto;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 3em;

    .label {
      background-color: ${({ theme }) => theme.colours.primary};
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      text-transform: capitalize;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info-wrapper {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0rem;

    & h2 {
      color: ${({ theme }) => theme.colours.secondary};
      font-weight: 200;
      font-size: 1.25rem;
    }
  }
`
