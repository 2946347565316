import { useEffect, useState } from "react"
import styled from "styled-components"

type FloorplanProps = {
  unit: any
}

const Floorplan: React.FC<FloorplanProps> = ({ unit }) => {
  const floorplan = unit?.floorplan.replace(
    "http://localhost:3000",
    import.meta.env.VITE_PAYLOAD_SERVER_URL
  )
  const [floorplanLoaded, setFloorplanLoaded] = useState(false)

  // Preload image
  useEffect(() => {
    const img = new Image()
    img.src = floorplan
    img.onload = () => {
      setFloorplanLoaded(true)
    }
    img.onerror = (error) => {
      console.error("Failed to load texture:", error)
      setFloorplanLoaded(false)
    }
  }, [floorplan])

  return (
    <Wrapper visible={floorplanLoaded}>
      {!floorplanLoaded && <div className="spinner" />}
      <img src={floorplan} alt="Floorplan" />
    </Wrapper>
  )
}

export default Floorplan

const Wrapper = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 70%;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: opacity 1s;
  }

  .spinner {
    position: absolute;
    height: 40px;
    width: 40px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #fff;
    border-radius: 50%;
    z-index: 1;
    animation: spin 1s linear infinite;
  }
`
