import Landing from "@pages/Landing"
import Feed from "@pages/Feed"
import Favourites from "@pages/favourites/Favourites"
import Contact from "@pages/Contact"

const routes = [
  // {
  //   name: "Home",
  //   path: "/",
  //   element: Landing,
  //   onNav: false,
  //   icon: null,
  // },
  {
    name: "Feed",
    path: "/feed",
    element: Feed,
    onNav: true,
    icon: "home",
  },
  {
    name: "Favourites",
    path: "/favourites",
    element: Favourites,
    onNav: true,
    icon: "heart",
  },
  {
    name: "Contact",
    path: "/contact",
    element: Contact,
    onNav: true,
    icon: "contact",
  },
]

export default routes
