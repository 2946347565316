import { useEffect, useRef } from "react"
import { Html } from "@react-three/drei"
import * as THREE from "three"
import { gsap } from "gsap"

import { useStore } from "@state/store"
import { useCustomAssets } from "@utils/useCustomAssets"

function PlanMesh() {
  const sectionIndex = useStore((s) => s.sectionIndex)
  const htmlRef = useRef<any>(null)

  const { model, texture, modelError, textureError } = useCustomAssets(
    `/models/floorplans/A.glb`,
    `/textures/floorplans/A.webp`
  )

  texture.flipY = false

  const animated = useRef<THREE.Mesh>()
  const duration = 1

  function tween() {
    if (animated.current) {
      gsap.to(animated.current.material, {
        delay: 0.5, // wait for the stack modal to slide in to avoid glitch
        opacity: sectionIndex === 0 ? 1 : 0,
        duration: duration * 1.15,
      })
      gsap.to(animated.current.position, {
        y: sectionIndex === 0 ? 0.1 : -1.5,
        duration,
      })
      gsap.to(animated.current.rotation, {
        y: sectionIndex === 0 ? -0.15 : -1.6,
        duration,
      })
    }
  }

  useEffect(() => {
    tween()
  }, [sectionIndex])

  return (
    <group scale={0.4}>
      {modelError || textureError ? (
        <Html ref={htmlRef} center style={{ width: "max-content" }}>
          3D model not found
        </Html>
      ) : (
        <mesh
          geometry={model.nodes.mesh_0.geometry}
          position={[0, -1.5, 0]}
          rotation={[0, -1.6, 0]}
          ref={animated}
        >
          <meshBasicMaterial
            opacity={0}
            map={texture}
            toneMapped={false}
            side={THREE.FrontSide}
            transparent
          />
        </mesh>
      )}
    </group>
  )
}

export default PlanMesh
