export function toggleArrayItem(arr: any[] | never[], item: any) {
  let newArr = [...arr]
  newArr = newArr.includes(item)
    ? newArr.filter((n) => n !== item)
    : [...newArr, item]
  return newArr
}

export function lerp(start, end, totalTime, elapsedTime) {
  elapsedTime = Math.max(0, Math.min(totalTime, elapsedTime))

  const timeValue = elapsedTime / totalTime
  const value = start + (end - start) * timeValue
  return value
}

export function organizeAspects(arr) {
  const value = (x) => {
    let v
    switch (x) {
      case "N":
        v = 0
        break
      case "E":
        v = 1
        break
      case "S":
        v = 2
        break
      case "W":
        v = 3
        break
      default:
        break
    }
    return v
  }
  let isConsecutive = true
  let aspects = arr?.sort((a, b) => {
    a = value(a)
    b = value(b)
    return a === b ? 0 : a > b ? 1 : -1
  })
  let valueArr = aspects.map((a) => value(a))
  let ratio

  for (let i = 0; i < valueArr.length; i++) {
    if (i !== valueArr.length - 1 && isConsecutive) {
      isConsecutive = valueArr[i] + 1 === valueArr[i + 1]
      ratio = valueArr[i] + 1
    } else {
      break
    }
  }

  function rotateArr(arr, spaces) {
    let newArr = [...arr]
    for (let i = 0; i < spaces; i++) {
      newArr.push(newArr.shift())
    }
    return newArr
  }
  return isConsecutive ? aspects : rotateArr(aspects, ratio)
}

export function linearMap(value, low1, high1, low2, high2) {
  return ((value - low1) * (high2 - low2)) / (high1 - low1) + low2
}

export function randomNumberInRange(min, max) {
  return Math.random() * (max - min) + min
}

export function isNthElement(i, nth) {
  return i % nth === nth - 1
}

export function formatPrice(price: number) {
  return price
    .toLocaleString("en-US", {
      style: "currency",
      currency: "GBP",
    })
    .replace(".00", "")
}

export default function urlBase64ToUint8Array(
  base64String: string
): Uint8Array {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
