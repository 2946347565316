import { useEffect, useState } from "react"
import styled from "styled-components"
import PreviewCard from "@components/PreviewCard"
import { useStore } from "@state/store"
import {
  fetchCustomerFavourites,
  fetchFavouritesFromURL,
} from "@utils/requests"
import { useAuth } from "@auth/AuthContext"
import StackModal from "@components/Modal/StackModal"
import FavCard from "./components/FavCard"

export default function Favourites() {
  const { user } = useAuth()
  const favourites = useStore((s) => s.favourites)
  const selectedUnit = useStore((s) => s.selectedUnit)
  const { setLocalState } = useStore((s) => ({
    setLocalState: s.setLocalState,
  }))

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const getFavouritesAsync = async () => {
      // const data = await fetchCustomerFavourites(user.QRID)
      const projectId = localStorage.getItem("projectId")
      const encodedUnits = localStorage.getItem("encodedUnits")

      const data = await fetchFavouritesFromURL(projectId, encodedUnits)

      if (data) {
        setLocalState({ favourites: data })
      }
    }

    getFavouritesAsync()

    // if (user.id) {
    //   getFavouritesAsync()
    // }
  }, [user?.id, setLocalState])

  const onClose = () => {
    setIsOpen(false)
    setLocalState({ sectionIndex: 0 })
  }

  return (
    favourites.length && (
      <>
        <FavoritesWrapper>
          {favourites?.map((favourite) => {
            const { id, unitName, price, floorplan } = favourite
            return (
              <PreviewCard
                key={id}
                status={favourite?.availability}
                buttonClick={() => {
                  setLocalState({ selectedUnit: favourite })
                  setIsOpen(true)
                }}
                data={{
                  title: `Unit ${unitName}`,
                  image: floorplan,
                }}
              />
            )
          })}

          <StackModal
            isOpen={isOpen && selectedUnit}
            onClose={onClose}
            isFullScreen={true}
          >
            <FavCard unit={selectedUnit} onClose={onClose} />
          </StackModal>
        </FavoritesWrapper>
      </>
    )
  )
}

const FavoritesWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2em 2em;
  margin-top: 2em;
  overflow: hidden scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`
