import Button from "@components/UI/Button"
import { useStore } from "@state/store"
import { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import styled, { css, keyframes } from "styled-components"
import { ChevronLeftIcon } from "@radix-ui/react-icons"

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  isFullScreen?: boolean
  style?: React.CSSProperties
}

const StackModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  isFullScreen = false,
  style,
}) => {
  const [showModal, setShowModal] = useState(isOpen)
  const [isAnimating, setIsAnimating] = useState(false)
  const setLocalState = useStore((s) => s.setLocalState)

  useEffect(() => {
    if (isOpen) {
      setShowModal(true)
      setIsAnimating(true)
      setLocalState({ isLayoutShifted: true })
    } else {
      setIsAnimating(true)
      setLocalState({ isLayoutShifted: false })
      setTimeout(() => {
        setShowModal(false)
        setIsAnimating(false)
      }, 300) // Ensure this matches the animation duration
    }
  }, [isOpen])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose()
      }
    }

    document.addEventListener("keydown", handleEscape)
    return () => document.removeEventListener("keydown", handleEscape)
  }, [onClose])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else if (!isAnimating) {
      document.body.style.overflow = ""
    }
  }, [isOpen, isAnimating])

  if (!showModal) return null

  return ReactDOM.createPortal(
    <Backdrop onClick={onClose} isOpen={isOpen} isFullScreen={isFullScreen}>
      <ModalContainer
        style={style}
        isFullScreen={isFullScreen}
        isOpen={isOpen}
        onClick={(e) => e.stopPropagation()}
      >
        <Header>
          <Button
            variant="outline"
            icon={<ChevronLeftIcon color="#fff" height={30} width={30} />}
            style={{ border: "none" }}
            onClick={onClose}
          />
        </Header>
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </Backdrop>,
    document.body
  )
}

export default StackModal

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`

const Backdrop = styled.div<{ isOpen: boolean; isFullScreen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
`

const ModalContainer = styled.div<{ isFullScreen: boolean; isOpen: boolean }>`
  background: ${({ theme }) => theme.colours.secondary};
  width: 100%;
  height: 100%;
  position: relative;

  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.3s ease-out;
  transition: transform 0.3s ease-out;

  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
  z-index: 1;
`

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  padding-top: var(--header-height);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`
