import React, { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { loginCustomer } from "@utils/requests"

interface AuthContextType {
  user: any | null
  login: (
    QRID: string
  ) => Promise<{ success: boolean; message: string; user: any }>
  logout: () => void
  loading: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) throw new Error("useAuth must be used within an AuthProvider")
  return context
}

export const AuthProvider: React.FC<any> = ({ children }) => {
  const [user, setUser] = useState<any | null>(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const storedUser = localStorage.getItem("user")
    if (storedUser) {
      setUser(JSON.parse(storedUser))
    }
    setLoading(false)
  }, [])

  const login = async (QRID: string) => {
    setLoading(true)
    try {
      const { success, message, user } = await loginCustomer(QRID)
      if (success && user) {
        localStorage.setItem("user", JSON.stringify(user))
        setUser(user)
        return { success: true, message, user }
      } else {
        throw new Error(message)
      }
    } catch (error) {
      console.error("Login failed:", error)
      return {
        success: false,
        message: "Login failed: Invalid QRID",
        user: null,
      }
    } finally {
      setLoading(false)
    }
  }

  const logout = () => {
    setUser(null)
    localStorage.removeItem("user")
    navigate("/login")
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  )
}
