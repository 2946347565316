import { useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import PWAIcons from "@components/PWAIcons"
import { useStore } from "@state/store"
import routes from "../../routes/routes"
import { useBranding } from "@branding/BrandingContext"

function Navbar() {
  const [badgeCount, setBadgeCount] = useState({
    Feed: 0,
    Favourites: 0,
    Contact: 0,
  })
  const navigate = useNavigate()
  const { theme: branding } = useBranding()
  const { navDurationSec, handleNav } = useStore((s) => ({
    navDurationSec: s.navDurationSec,
    handleNav: s.handleNav,
  }))

  return (
    <NavWrapper>
      {routes.map((route) => {
        return (
          route.onNav && (
            <PWAIcons
              key={route.name}
              icon={route.icon as any}
              // color={branding.colours.secondary}
              color="rgba(255, 255, 255, 0.5)"
              className="iconSize"
              opacity={location.pathname === route.path ? 1 : 0.6}
              onClick={() => {
                handleNav(() => navigate(route.path))
              }}
              style={{
                transitionDuration: `${navDurationSec}s`,
              }}
              badge={badgeCount[route.name]}
            />
          )
        )
      })}
    </NavWrapper>
  )
}

export default Navbar

const NavWrapper = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 100%;
  height: var(--nav-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media (orientation: landscape) {
    flex-direction: column;
  }

  .iconSize {
    width: min(7vw, 7vh);

    > * {
      transition-property: fill;
      transition-timing-function: ease-in-out;
    }
  }
`
