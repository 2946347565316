import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import gsap from "gsap"

import { useStore } from "@state/store"
import PWAIcons from "@components/PWAIcons"
import Button from "@components/UI/Button"
import { useAuth } from "@auth/AuthContext"
import { requestNotificationPermission } from "@utils/requests"

export default function Landing() {
  const { user } = useAuth()
  const [message, setMessage] = useState("")
  const { newContent, handleNav, setLocalState, notifications } = useStore(
    (s) => ({
      newContent: s.newContent,
      handleNav: s.handleNav,
      setLocalState: s.setLocalState,
      notifications: s.notifications,
    })
  )
  const navigate = useNavigate()
  const bannerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!bannerRef.current) return
    gsap.to(bannerRef.current, {
      autoAlpha: newContent ? 1 : 0,
      duration: 0.3,
    })
  }, [newContent])

  const isPWA = () => {
    return window.matchMedia("(display-mode: standalone)").matches
  }

  useEffect(() => {
    if (isPWA()) {
      setMessage("The app is running as a PWA.")
    } else {
      setMessage("The app is running in a browser.")
    }
  }, [])

  const handleRequestNotificationPermission = async () => {
    if (!user) return
    const res = await requestNotificationPermission(user.id)
    console.log("Notification permission request response:", res)

    if (res === "Push notifications enabled successfully.") {
      console.log("Success! Notifications are enabled.")
      setLocalState({ notifications: "granted" })
    } else if (res.includes("Error")) {
      console.error("An error occurred:", res)
      // Handle the error...
    } else {
      console.log(res) // Log other messages like denied or dismissed
    }
  }

  return (
    <LandingWrapper className="landing-wrapper">
      <Content>
        {!isPWA() && (
          <div className="welcome">
            Welcome! Please add this app to your homescreen to see your
            favourite units, and receive notifications about our latest offers
            and availability.
          </div>
        )}

        {isPWA() && (
          <>
            {notifications === "default" && (
              <Button
                variant="fill"
                color="secondary"
                style={{ width: "100%", borderRadius: 50 }}
                onClick={handleRequestNotificationPermission}
              >
                Allow Notifications
              </Button>
            )}

            <Button
              variant="fill"
              color="secondary"
              style={{ width: "100%", borderRadius: 50 }}
              onClick={() => {
                handleNav(() => navigate("/feed"))
              }}
            >
              Explore
            </Button>
          </>
        )}

        <NewContentBanner ref={bannerRef}>
          <Message>
            <PWAIcons
              icon="close"
              color="white"
              width={`${Math.min(innerWidth * 0.05, innerHeight * 0.05)}px`}
              strokeWidth={5}
              onClick={() => {
                setLocalState({
                  newContent: false,
                })
              }}
            />
            {/* TO DO Define message structure from backend */}
            <p>
              Hey Sara,
              <br />
              <br />
              What are your thoughts on these new residences?
              <br />
              <br />
              <br />
              Looking forward to chatting soon!
              <br />
              <br />
              <br />
              - Katie
              <br />
              <br />
              <br />
            </p>
            <Button
              onClick={() => {
                handleNav(() => {
                  setLocalState({
                    newContent: false,
                  })
                  navigate("/favourites")
                })
              }}
            >
              View Residences
            </Button>
          </Message>
        </NewContentBanner>
      </Content>
    </LandingWrapper>
  )
}

const LandingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 0 15%;

  .welcome {
    font-size: 1em;
    text-align: center;
    color: #fff;
  }
`

const NewContentBanner = styled.div`
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`

const Message = styled.div`
  position: relative;
  width: 90%;
  height: 70%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & div {
    position: absolute;
    right: min(5vh, 5vw);
    top: min(5vh, 5vw);
  }

  & p {
    position: relative;
    font-size: 1em;
    width: 80%;
  }
`
