import { create } from "zustand"

import { IBranding } from "@utils/models"

interface InitialState {
  navigating: boolean
  navDurationSec: number
  handleNav: (callback: () => void) => void
  branding: IBranding | null
  page: "HOME" | "FAVOURITES" | "CONTACT"
  customerData: any
  favourites: any[]
  loading: boolean
  activeArticle: string | null
  popupCard: boolean
  newContent: boolean
  isLayoutShifted: boolean
  selectedUnit: any
  sectionIndex: number
  notifications: "granted" | "denied" | "default"
  setLocalState: (state: Partial<InitialState>) => void
}

export const useStore = create<InitialState>()((set, get) => ({
  setLocalState: (stateToUpdate) => {
    set({ ...stateToUpdate })
  },
  selectedUnit: null,
  sectionIndex: 0,
  navigating: true,
  navDurationSec: 0.2,
  handleNav: (callback) => {
    set({ navigating: true })
    setTimeout(() => {
      callback()
    }, get().navDurationSec * 1150)
  },
  branding: null,
  loading: true,
  page: "HOME",
  customerData: {},
  favourites: [],
  activeArticle: null,
  popupCard: false,
  newContent: false,
  isLayoutShifted: false,
  notifications: "default",
}))
