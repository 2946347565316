import styled from "styled-components"
import { Fragment, useRef, useEffect, useState } from "react"
import gsap from "gsap"
import PreviewCard from "@components/PreviewCard"
import { useStore } from "@state/store"
import Article from "@components/Article"
import StackModal from "@components/Modal/StackModal"
import { fetchFeed } from "@utils/requests"

export default function Feed() {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState(null)
  const [articles, setArticles] = useState([])

  const { activeArticle, setLocalState } = useStore((s) => ({
    activeArticle: s.activeArticle,
    setLocalState: s.setLocalState,
  }))
  const articleRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const fetchFeedAsync = async () => {
      const res = await fetchFeed()

      const art = res.map((art: any) => {
        return {
          id: art.id,
          postDate: art.postDate,
          title: art.title,
          image: art.image.url,
          content: art.content,
        }
      })
      setArticles(art)
    }

    fetchFeedAsync()
  }, [])

  useEffect(() => {
    if (!articleRef.current) return
    gsap.to(articleRef.current, {
      autoAlpha: activeArticle ? 1 : 0,
    })
  }, [activeArticle])

  const onClose = () => {
    setIsOpen(false)
    setLocalState({ activeArticle: "" })
  }

  return (
    <Fragment>
      <FeedWrapper>
        {articles &&
          articles.map((art: any) => {
            return (
              <PreviewCard
                data={art}
                key={art.id}
                buttonClick={() => {
                  setData(art)
                  setLocalState({ activeArticle: art.id })
                  setIsOpen(true)
                }}
              />
            )
          })}
      </FeedWrapper>
      <StackModal
        isOpen={isOpen && activeArticle !== null}
        onClose={onClose}
        isFullScreen={true}
      >
        <Article data={data} />
      </StackModal>
    </Fragment>
  )
}

const FeedWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`
