import styled from "styled-components"

function LoadScreen(props) {
  return (
    <LoadingWrapper {...props}>
      <span className="loader"></span>
    </LoadingWrapper>
  )
}

export default LoadScreen

const LoadingWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: calc(infinity);
  top: 0;
  left: 0;

  display: grid;
  place-content: center;

  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
